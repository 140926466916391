import React from "react";
import { Link } from "gatsby";

import "@fortawesome/fontawesome-free/css/all.css";

const TopicBadge = (props) => {
  return (
    <Link
      to={"/topics/" + props.topic.slug.current}
      className="text-base font-light text-light"
    >
      <p
        className="transition-standard inline-block py-2 px-3 border border-link"
        style={{ backgroundColor: props.topic.color.hex || "#718096" }}
      >
        <i className={`mr-2 ${props.topic.icon || "fas fa-ban"}`}></i>
        <span>{props.topic.name || "Uncategorized"}</span>
      </p>
    </Link>
  );
};

export default TopicBadge;
