import React from "react";
import BackgroundImg from "gatsby-background-image";

import * as styles from "./TemplateTitle.module.css";

const TemplateTitle = (props) => {
  const content = (
    <>
      <div
        className={`flex justify-center items-center h-full ${styles.pageTitleContainer}`}
      >
        <div
          className={`md:w-3/4 w-full ${styles.pageTitleContent}`}
          style={
            props.color
              ? {
                  borderColor: props.color,
                }
              : null
          }
        >
          {props.children}
        </div>
      </div>
      <div className={styles.pageTitleOverlay}></div>
    </>
  );

  return props.image ? (
    <BackgroundImg className={styles.pageTitle} fluid={props.image}>
      {content}
    </BackgroundImg>
  ) : (
    <div className={`bg-gray-300 ${styles.pageTitle}`}>{content}</div>
  );
};

export default TemplateTitle;
