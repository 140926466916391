import React from "react";
import { graphql } from "gatsby";

import BlockContent from "@sanity/block-content-to-react";

import Layout from "../layouts/default";
import SEO from "../utils/seo";
import TopicBadge from "../components/TopicBadge";
import BlockContentImage from "../components/BlockContentImage";
import TemplateTitle from "../components/TemplateTitle";
import TemplateBackButton from "../components/TemplateBackButton";

import "../styles/style-normal.css";

const BlogPost = ({ data }) => {
  return (
    <Layout>
      <SEO
        title={`${data.post.title} | Blog`}
        image={data.post.mainImage.asset.url + "?h=400"}
        article={true}
      />
      <TemplateTitle image={data.post.mainImage.asset.fluid}>
        <TemplateBackButton title="Blog" parent="/blog" />
        <h1
          className="md:w-3/4 w-full text-dark inline md:text-5xl text-4xl font-serif break-words leading-snug mb-3"
          title="title-main"
        >
          {data.post.title}
        </h1>
        <h3 className="text-dark text-base opacity-75 mt-1">
          <span>Authored by</span>
          <span className="text-primary mx-1">{data.post.author.name}</span>
          on
          <span className="text-primary mx-1">
            {new Date(data.post._createdAt).toLocaleDateString(
              {},
              {
                weekday: "short",
                year: "numeric",
                month: "long",
                day: "numeric",
              }
            )}
          </span>
        </h3>
        <div className="mt-6">
          <TopicBadge topic={data.post.topic} />
        </div>
      </TemplateTitle>
      <section className="px-6 py-12">
        <article className="style-normal md:w-3/4 w-full mx-auto">
          <BlockContent
            blocks={data.post._rawBody || []}
            serializers={{
              types: { captionedImage: BlockContentImage },
            }}
            projectId={process.env.GATSBY_SANITY_ID}
            dataset={process.env.GATSBY_SANITY_DATASET}
          />
        </article>
      </section>
    </Layout>
  );
};

export const query = graphql`
  query BlogPostQuery($id: String) {
    post: sanityPost(_id: { eq: $id }) {
      title
      slug {
        current
      }
      author {
        name
      }
      topic {
        icon
        name
        color {
          hex
        }
        slug {
          current
        }
      }
      mainImage {
        asset {
          fluid(maxWidth: 1920) {
            ...GatsbySanityImageFluid
          }
          url
        }
      }
      _createdAt
      _rawBody
    }
  }
`;

export default BlogPost;
