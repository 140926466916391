import React from "react";
import { Link } from "gatsby";

import { FaArrowLeft } from "react-icons/fa";

const TemplateBackButton = (props) => {
  return (
    <div className="mb-6 text-light font-bold uppercase flex">
      <Link
        to={props.parent}
        className="px-3 py-1 bg-primary hover:bg-dark transition-standard rounded border border-link"
      >
        <FaArrowLeft className="fas fa-arrow-left mr-2" />
        <span>{props.title}</span>
      </Link>
    </div>
  );
};

export default TemplateBackButton;
